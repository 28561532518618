import React, { useState, useRef, useEffect } from 'react';
import './Header.css';

// Logo
import Logo from '../../assets/img/logo.png';
import navLinkIcon from '../../assets/img/nav-link-icon.png';

// Icons
import { FaBars } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import { NavLink } from "react-router-dom";

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const headerRef = useRef(null);

    const toggleMenu = () => setMenuOpen(prevState => !prevState);

    const closeMenu = (e) => {
        if (headerRef.current && !headerRef.current.contains(e.target)) {
            setMenuOpen(false);
            setDropdownOpen(false);
        }
    };

    const handleLinkClick = () => {
        setMenuOpen(false);  // Close the nav menu
        setDropdownOpen(false);  // Close the dropdown menu (just in case it's open)
    };

    const handleDropdownClick = () => {
        setDropdownOpen(false); // Close the dropdown menu
        setMenuOpen(false); // Close the main nav menu
    };

    useEffect(() => {
        document.addEventListener('click', closeMenu);
        return () => document.removeEventListener('click', closeMenu);
    }, []);

    return (
        <header className="header" ref={headerRef}>
            <nav className="nav container">
                <a
                    href="#" className="nav__logo" aria-label="Home">
                    <img src={Logo} alt="logo" className="nav-logo-img"/>
                </a>

                <div
                    className="nav__toggle"
                    onClick={toggleMenu}
                    aria-expanded={menuOpen}
                    aria-controls="navMenu"
                    role="button"
                    tabIndex="0"
                >
                    <FaBars className="nav__burger"/>
                </div>

                <div id="navMenu" className={`nav__menu ${menuOpen ? 'show-menu' : ''}`}>
                    <ul className="nav__list">
                        <div className="nav__list-data" onClick={toggleMenu}>
                            <img src={Logo} alt="logo" className="nav-logo-img"/>
                            <IoClose className="nav__close"/>
                        </div>

                        <li>
                            <NavLink
                                exact
                                to='/'
                                className='nav__link'
                                activeClassName='active'
                                onClick={handleLinkClick}
                            >
                                Home
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to='/about'
                                className='nav__link'
                                activeClassName='active'
                                onClick={handleLinkClick}
                            >
                                About
                            </NavLink>
                        </li>

                        {/* Dropdown */}
                        <li className="dropdown__item" >
                            <div className="nav__link dropdown-nav-link" onClick={() => setDropdownOpen(!dropdownOpen)}>
                                LICENCE TYPES
                                <img src={navLinkIcon} className='nav-link-icon' alt='nav-link-icon'/>
                            </div>

                            {dropdownOpen && (
                                <ul className="dropdown__menu">
                                    <li>
                                        <NavLink
                                            to='/banking'
                                            className='dropdown__link'
                                            activeClassName='active'
                                            onClick={handleDropdownClick}
                                        >
                                            BANKING
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to='/banking-with-crypto'
                                            className='dropdown__link'
                                            activeClassName='active'
                                            onClick={handleDropdownClick}
                                        >
                                            BANKING WITH CRYPTO
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to='/brokerage'
                                            className='dropdown__link'
                                            activeClassName='active'
                                            onClick={handleDropdownClick}
                                        >
                                            BROKERAGE/FX
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to='/online-gaming'
                                            className='dropdown__link'
                                            activeClassName='active'
                                            onClick={handleDropdownClick}
                                        >
                                            GAMING
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to='/insurance-law'
                                            className='dropdown__link'
                                            activeClassName='active'
                                            onClick={handleDropdownClick}
                                        >
                                            INSURANCE
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to='/finance'
                                            className='dropdown__link'
                                            activeClassName='active'
                                            onClick={handleDropdownClick}
                                        >
                                            FINANCE
                                        </NavLink>
                                    </li>
                                </ul>
                            )}
                        </li>

                        <li>
                            <NavLink
                                to='/anjouan-register'
                                className='nav__link'
                                activeClassName='active'
                                onClick={handleLinkClick}
                            >
                                ANJOUAN REGISTER
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to='/acts-and-documents'
                                className='nav__link'
                                activeClassName='active'
                                onClick={handleLinkClick}
                            >
                                ACT & DOCUMENTS
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to='/contact'
                                className='nav__link'
                                activeClassName='active'
                                onClick={handleLinkClick}
                            >
                                CONTACT
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
    );
};

export default Header;
