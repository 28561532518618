import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Header from "./Components/Header/Header";
import Home from "./Components/Pages/Home/Home";
import Footer from "./Components/Footer/Footer";
import About from "./Components/Pages/About/About";
import Banking from "./Components/Pages/Banking/Banking";
import BankingCrypto from "./Components/Pages/Banking With Crypto/BankingCrypto";
import OnlineGaming from "./Components/Pages/Online Gaming/OnlineGaming";
import Brokerage from "./Components/Pages/Brokerage/Brokerage";
import InsuranceLaw from "./Components/Pages/Insurance Law/InsuranceLaw";
import Finance from "./Components/Pages/Finance/Finance";
import AnjouanRegister from "./Components/Pages/Anjouan Register/AnjouanRegister";
import ActsDocuments from "./Components/Pages/ACTS AND DOCUMENTS/ActsDocuments";
import Contact from "./Components/Pages/Contact/Contact";
import ScrollToTop from "./Components/Utils/ScrollToTop";

function App() {
  return (
    <BrowserRouter>
      <Header/>
        <ScrollToTop/>
        <Routes>
            <Route path="/" exact element={<Home/>} />
            <Route path="/about" element={<About/>} />
            <Route path="/banking" element={<Banking/>} />
            <Route path="/banking-with-crypto" element={<BankingCrypto/>} />
            <Route path="/brokerage" element={<Brokerage/>} />
            <Route path="/online-gaming" element={<OnlineGaming/>} />
            <Route path="/insurance-law" element={<InsuranceLaw/>} />
            <Route path="/finance" element={<Finance/>} />
            <Route path="/anjouan-register" element={<AnjouanRegister/>} />
            <Route path="/acts-and-documents" element={<ActsDocuments/>} />
            <Route path="/contact" element={<Contact/>} />

        </Routes>
        
        <Footer/>
    </BrowserRouter>
  );
}

export default App;
