import React, { useState } from 'react';
import './About.css'; // Assuming your CSS is saved here

//images 
import AnjouanMap_1 from '../../../assets/img/anjouan-map-01.png';
import AnjouanMap_2 from '../../../assets/img/anjouan-map-02.png';

const About = () => (
    <main className='main'>
        <section className="about-section">
            <div className="section-heading-container">
                <h2 className='section-heading'>
                    The Autonomous Government Of
                </h2>
                <h2 className='section-subheading'>
                    The Island Of Anjouan
                </h2>

                <div className="overlay"></div>
            </div>

            <div className="about-container container grid">
                <div className='about-image-container'>
                    <img src={AnjouanMap_1} className='about-image' alt='map'/>
                </div>

                <div className='about-details-container'>
                    <span className='section-subtitle'>
                        ABOUT US
                    </span>
                    <h1 className='section-title'>
                        The Autonomous Government Of The Island Of Anjouan
                    </h1>
                    <p className='section-description'>
                        Established in 2001, the Anjouan Offshore Finance Authority (AOFA) proudly serves as the primary
                        regulatory authority for the Financial Sector in Anjouan, Comoros. With an unwavering commitment
                        to maintaining the highest standards of integrity and transparency, AOFA diligently upholds its
                        regulatory powers and mandate derived from the Comoros’s Constitution of 2001.
                        <br/><br/>
                        At AOFA, we play a vital role in ensuring a robust and thriving financial ecosystem in Anjouan.
                        Our core objective is to safeguard the interests of all stakeholders, while fostering an
                        environment conducive to sustainable economic growth. Through our comprehensive regulatory
                        framework, we facilitate responsible financial practices, protect against money laundering, and
                        enforce adherence to the Money Laundering Prevention Act (Government Notice 008 of 2005).
                        <br/><br/>
                        This website serves as a comprehensive resource centre, designed to provide valuable information
                        and guidance to individuals, businesses, and institutions seeking to engage with the financial
                        sector in Anjouan. Here, you will find an array of essential resources, regulatory guidelines,
                        and up-to-date information pertaining to our operations, licensing procedures, and compliance
                        requirements.
                    </p>
                </div>
            </div>
        </section>

        <section className="about-section">
            <div className="about-content container grid">
                <div className='about-content-heading'>
                    <div>
                        <span className='section-subtitle'>
                            OUR VISSION
                        </span>
                        <h2 className='section-title'>
                            Key Features Of Our Website:
                        </h2>
                    </div>
                    <div className='about-content-heading-image'>
                        <img src={AnjouanMap_2} className='about-content-image' alt='map'/>
                    </div>
                </div>

                <div className='about-table'>
                    <div className='about-table-column'>
                        <h3>
                            <strong>
                                Licensing And Registration
                            </strong>
                        </h3>
                        <p>
                            Obtain detailed information on the licensing process and registration requirements for
                            financial entities, including banks, insurance companies, investment firms, and more.
                        </p>
                    </div>

                    <div className='about-table-column'>
                        <h3>
                            <strong>
                                Regulatory Framework
                            </strong>
                        </h3>
                        <p>
                            Explore our comprehensive regulatory framework, including laws, regulations, and guidelines,
                            to ensure compliance and promote best practices in the financial sector. </p>
                    </div>

                    <div className='about-table-column'>
                        <h3>
                            <strong>
                                Anti-Money Laundering Measures
                            </strong>
                        </h3>
                        <p>
                            Gain insights into our rigorous efforts to combat money laundering, mitigate financial
                            risks, and promote transparency, as mandated by the Money Laundering Prevention Act.
                        </p>
                    </div>

                    <div className='about-table-column'>
                        <h3>
                            <strong>
                                News And Updates
                            </strong>
                        </h3>
                        <p>
                            Stay informed about the latest developments, policy changes, and industry trends through our regularly updated news section, helping you make well-informed financial decisions. Contact and Support: Reach out to our dedicated team of experts who are ready to assist you with any queries or concerns you may have regarding our regulatory framework, licensing procedures, or other related matters.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    </main>
);

export default About;
