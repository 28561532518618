import React, { useState } from 'react';

// icons
import { TiTick } from "react-icons/ti";


const Finance = () => (
    <main className='main'>
        <section className="about-section">
            <div className="section-heading-container">
                <h2 className='section-heading'>
                    Online Gaming
                </h2>
            </div>

            <div className="banking-container container grid">
                <div className="about-content">
                    <p className='section-paragraph'>
                        The OFA has specific requirements for obtaining a Finance License with a focus on crypto
                        operations, detailed under the Financial Services Law. Here’s an overview of the key features
                        and requirements:
                    </p>
                    <h3>
                        License Type:
                    </h3>
                    <p className='container-paragraph'>
                        <strong>Specifics of License:</strong> An International Finance License (Class B) is offered,
                        specifically for financial institutions operating offshore. This license permits comprehensive
                        financial services, including those related to cryptocurrencies, outside of Anjouan, Union of
                        Comoros.
                    </p>

                    <h3>
                        Services Included:
                    </h3>
                    <p className='container-paragraph'>
                        <strong>Scope of Services:</strong> The license allows for a wide range of financial services
                        such as asset management, corporate financing, forex trading, and includes specialized services
                        for cryptocurrencies like digital asset trading and management, crypto custody services, and
                        blockchain-based financial services. </p>

                    <h3 className='container-title'>
                        Regulatory Framework:
                    </h3>
                    <p className='container-paragraph'>
                        <strong>Regulation and Oversight:</strong> The license is regulated by the Offshore Finance
                        Authority (OFA), which mandates strict adherence to the Financial Services Law. Financial
                        institutions are required to notify the OFA about any significant changes in their operations or
                        share ownership. </p>

                    <h3 className='container-title'>
                        Formation and Application Process:
                    </h3>
                    <p className='container-paragraph'>
                        <strong>Application Efficiency:</strong> The process to form a financial institution with a
                        crypto focus is designed to be efficient, with approvals potentially granted quickly if all
                        conditions are met.
                    </p>
                    <p className='container-paragraph'>
                        <strong>Infrastructure Requirements:</strong> While not obligatory, it is advisable for
                        institutions to have local offices or staff, arrangements for which can be facilitated
                        economically.
                    </p>

                    <h3 className='container-title'>
                        Documentation and Due Diligence:
                    </h3>
                    <p className='container-paragraph'>
                        <strong>Comprehensive Business Plan:</strong> Must outline the financial activities, target
                        markets, shareholder details, and specifics about the ultimate beneficial owners.
                    </p>

                    <p className='container-paragraph'>
                        <strong>KYC Documents:</strong> Required documents include CVs of all directors, full contact
                        details of directors and shareholders, bank references, certified copies of passports, proof of
                        addresses, and business or legal references for all beneficial owners.
                    </p>

                    <p className='container-paragraph'>
                        <strong>Legal and Compliance Documents:</strong> Necessary documentation includes incorporation certificates, latest audited financial statements, and a declaration of no criminal record for all beneficial owners.
                    </p>

                    <h3>
                        Compliance and Reporting:
                    </h3>
                    <p className='container-paragraph'>
                        <strong>Annual Reporting:</strong> All licensed institutions must annually submit detailed financial statements to the Anjouan Offshore Finance Authority.
                    </p>

                    <h3>Enhanced Due Diligence for Crypto Operations:</h3>
                    <p className='container-paragraph'>
                        <strong>Additional Scrutiny:</strong> Given the involvement with cryptocurrencies, additional due diligence measures are required, reflecting the complexities and regulatory requirements specific to crypto operations.
                    </p>

                    <p className='container-paragraph'>
                        This specialized finance license is designed to integrate traditional financial services with modern crypto operations, providing a robust framework for the operation of financial services in a regulated and transparent environment.
                    </p>
                </div>

                <div className='about-card-container'>
                    <article className='about-card'>
                        <h3>
                            Licenses
                        </h3>
                        <p className='section-paragraph'>
                            <h2>
                                <TiTick/>
                            </h2>
                            International Banking Licenses
                        </p>
                        <p className='section-paragraph'>
                            <h2>
                                <TiTick/>
                            </h2>
                            Insurance Licenses
                        </p>
                        <p className='section-paragraph'>
                            <h2>
                                <TiTick/>
                            </h2>
                            Online Gaming Licenses
                        </p>
                        <p className='section-paragraph'>
                            <h2>
                                <TiTick/>
                            </h2>
                            Brokerage License
                        </p>
                    </article>
                    <article className='about-card'>
                        <h3>
                            Services
                        </h3>
                        <p className='section-paragraph'>
                            <h2>
                                <TiTick/>
                            </h2>
                            International Banking Companies (IBC)
                        </p>
                        <p className='section-paragraph'>
                            <h2>
                                <TiTick/>
                            </h2>
                            Fully serviced offices in
                            Mutsamudu
                        </p>
                        <p className='section-paragraph'>
                            <h2>
                                <TiTick/>
                            </h2>
                            Citizenship Program
                        </p>
                    </article>
                </div>
            </div>
        </section>
    </main>
);

export default Finance;
