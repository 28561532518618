import React, { useState } from 'react';

// icons
import { TiTick } from "react-icons/ti";


const InsuranceLaw = () => (
    <main className='main'>
        <section className="about-section">
            <div className="section-heading-container">
                <h2 className='section-heading'>
                    Online Gaming
                </h2>
            </div>

            <div className="banking-container container grid">
                <div className="about-content">
                    <p className='section-paragraph'>
                        The International Insurance Act 2005 lays out the framework to facilitate the establishment of an international insurance business from Anjouan. 
                        <br/>
                        Application for insurance licences is made to the Anjouan Commission for International Insurance by companies already holding an IBC ( International Business Company). 
                        <br/>
                        There is one class of insurance business authorised by the Anjouan Commission which is a class 2 General Insurance License, insuring different parties? risks.
                        <br/>
                        In the case of third party business the insurance company should demonstrate access to the necessary underwriting and analytical skills, financial soundness and a good track record. Full details of all programmes to be underwritten must be submitted for approval to the Anjoiuan Commission.
                        <br/>
                        The one class of insurance licences that can be issued by the Anjouan Commission for International Insurance is Class II General International Licence.
                    </p>
                    
                    <h3>
                        Formation
                    </h3>
                    <p className='section-paragraph'>
                        An application to form an international insurance company should be submitted to the Anjouan Commission for International Insurance. Applications must be submitted through the registered agent, Anjouan Corporate Services Ltd, and should be made on the prescribed application forms as follows
                    </p>
                    <p className='section-paragraph'>
                        An application for the consent of the Commissioner and the Authority to register as an insurer. Personal Questionnaire. Every Director, Controller, Manager and/or Promoter (Form PQ) of a person that applies for a licence under the Act or who submits an application for consent to register. Financial Statements. Every application for a licence to carry on international insurance business in Class II pursuant to the Act shall be accompanied by a Financial Statement which shall be made on Form FS. Business Plan. Every application for a licence to carry on international insurance business pursuant to the Act shall be accompanied by a Business Plan which shall be made on Form BP. Furthermore the application form shall be accompanied buy the necessary KYC and due diligence documents as follows:
                    </p>
                    
                    <h3>
                        KYC and due diligence
                    </h3>
                    <p className='section-paragraph'>
                        Please submit the following documents for each of the beneficial owner, director, manager, authorised signatory, shareholder/member of the Entity or agent under power of attorney.
                    </p>
                    
                    <h3>
                        (a) For Individual
                    </h3>
                    <p className='section-paragraph'>
                        A certified copy of passport or current driving licence or national identity card showing the name, date and place of birth, nationality, signature of holder and data of issue/expiry. The document must be certified by a banker, lawyer or actuary or notary or accountant holding a recognized professional qualification. Proof of residential address. Acceptable evidence includes an original utility bill, bank or credit card statement. Original Bank Reference.
                    </p>
                    
                    <h3>
                        (b) For Corporate/Partnership
                    </h3>
                    
                    <p className='section-paragraph'>
                        Certificate of incorporation or registration and the memorandum and articles of association or constitution or deed of partnership or Act de Société as the case may be. 
                        <br/>
                        Document has to be certified by banker, lawyer or actuary or notary or accountant holding a recognized professional qualification. Original Bank Reference. 
                        <br/>
                        A certified true copy of latest audited account or annual report.
                    </p>
                    
                </div>

                <div className='about-card-container'>
                    <article className='about-card'>
                        <h3>
                            Insurance Law
                        </h3>
                        <p className='section-paragraph'>
                            An Applicant for a licence under the Act must maintain a net worth with fully paid-up capital or a guarantee in US Dollars or equivalent in another currency. Class of Licence Fully Paid-Up Capital Fund or Guarantee Class II Euros 100,000
                        </p>
                       
                    </article>

                    <article className='about-card'>
                        <h3>
                            Licenses
                        </h3>
                        <p className='section-paragraph'>
                            <h2>
                                <TiTick/>
                            </h2>
                            International Banking Licenses
                        </p>
                        <p className='section-paragraph'>
                            <h2>
                                <TiTick/>
                            </h2>
                            Insurance Licenses
                        </p>
                        <p className='section-paragraph'>
                            <h2>
                                <TiTick/>
                            </h2>
                            Online Gaming Licenses
                        </p>
                        <p className='section-paragraph'>
                            <h2>
                                <TiTick/>
                            </h2>
                            Brokerage License
                        </p>
                    </article>
                    <article className='about-card'>
                        <h3>
                            Services
                        </h3>
                        <p className='section-paragraph'>
                            <h2>
                                <TiTick/>
                            </h2>
                            International Banking Companies (IBC)
                        </p>
                        <p className='section-paragraph'>
                            <h2>
                                <TiTick/>
                            </h2>
                            Fully serviced offices in
                            Mutsamudu
                        </p>
                        <p className='section-paragraph'>
                            <h2>
                                <TiTick/>
                            </h2>
                            Citizenship Program
                        </p>
                    </article>
                </div>
            </div>
        </section>
    </main>
);

export default InsuranceLaw;
