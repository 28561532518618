import React, { useState } from 'react';
import './Banking.css'; // Assuming your CSS is saved here

// icons
import { TiTick } from "react-icons/ti";


const Banking = () => (
    <main className='main'>
        <section className="about-section">
            <div className="section-heading-container">
                <h2 className='section-heading'>
                    BANKING LAW
                </h2>
            </div>

            <div className="banking-container container grid">
                <div className="about-content">
                    <p className='section-paragraph'>
                        The OFA has certain requirements for a Banking License to be satisfied by all offshore banks, namely the Banking Law. The key features of the Law are as follows: The Law establishes a licensing regime for banks and savings institutions; licences which are issued by the OFA are for offshore banks only. A Bank must inform the OFA about any change in its share ownership.                    </p>
                    <h2>
                        BANKING LICENSES
                    </h2>
                    <p className='section-paragraph'>
                        An International banking License (Class B) is the only Banking License offered in Anjouan and is an offshore banking licence granted, by the OFA . This is a full-unrestricted Banking licence, for all Banking services outside of Anjouan, Union of Comoros. It encompasses banking business and investment banking business conducted in all major foreign currencies. Services such as fund administration, custodial services, trusteeship, international portfolio management, money market and other treasury operations may be undertaken by an offshore bank.
                    </p>
                    <p className='section-paragraph'>
                        License Type: The only license offered in Anjouan for this purpose is an International Banking License (Class B), designated for offshore banks only. This is a full-unrestricted license allowing for all banking services outside of Anjouan, Union of Comoros.
                    </p>
                    
                    <h3>
                        Formation
                    </h3>
                    <p className='section-paragraph'>
                        Forming a bank is a relatively quick process due to the lack of bureaucracy. Approval can be granted swiftly if the necessary requirements are met.
                        There are no requirements for local offices or staff, although we would suggest that all companies have this facility which can be arranged at a minimal cost.
                        Application for permission for a banking licence should be made to the OFA after an IBC is obtained after completing an Application Form from the Registrar of International Businesses. 
                        On top of the KYC and due diligence requirement, the following documents are required: A detailed business plan of the company: Activities of the bank; Countries in which the bank is looking to invest; The percentage of ownership by the shareholders; The details of the ultimate beneficial owners (chart may help if many); Any other useful information to support the application. CVs of all the directors Full contact details of the directors and shareholders (KYC questionnaire) Bank reference for all the beneficial owners Clear certified passport copies of all beneficial owners Proof of addresses (utility bills) Business or legal reference for the beneficial owners Legal doc for the shareholding companies (articles, certificate of incorporation etc) Latest audited financial statements for all shareholding companies (if any) A certificate to prove that the beneficial owners do not have a criminal record

                        Furthermore, all companies with banking licenses are required to submit their annual accounts to the Anjouan Offshore Finance Authority
                    </p>
                    
                    <h3>
                        KYC AND DUE DILIGENCE
                    </h3>
                    <p className='section-paragraph'>
                        A charge is made for each director / shareholder for due diligence (cost is dependent of country of residence ), in the event that a client is not accepted for a license this payment is not refundable. Please submit the following documents for each of the beneficial owner, director, manager, authorised signatory, shareholder/member of the Entity or agent under power of attorney.
                    </p>
                    
                    <h3>
                        (a) For Individual
                    </h3>
                    <p className='section-paragraph'>
                        A certified copy of passport or current driving licence or national identity card showing the
                        name, date and place of birth, nationality, signature of holder and data of issue/expiry. The
                        document must be certified by a banker, lawyer or actuary or notary or accountant holding a
                        recognized professional qualification, Proof of residential address. Acceptable evidence
                        includes an original utility bill, bank or credit card statement. Original Bank Reference.
                    </p>
                    
                    <h3>
                        (b) For Corporate/Partnership
                    </h3>
                    <p className='section-paragraph'>
                        Certificate of incorporation or registration and the memorandum and articles of association or constitution or deed of partnership or Act de Société as the case may be. Document has to be certified by banker, lawyer or actuary or notary or accountant holding a recognized professional qualification. Original Bank Reference. A certified true copy of latest audited account or annual report. A Certificate of good standing (for Société). List of substantial shareholders (who holds not less than 5% of the voting power exercisable at the meeting of shareholder/member) and a list of directors, and corporate structure. A certified copy of Board resolution granting authority to its managers, officers or employees to transact business on its behalf.. This is a full-unrestricted Banking licence, for all Banking services outside of Anjouan, Union of Comoros. It encompasses banking business and investment banking business conducted in all major foreign currencies. Services such as fund administration, custodial services, trusteeship, international portfolio management, money market and other treasury operations may be undertaken by an offshore bank.
                    </p>
                </div>

                <div className='about-card-container'>
                    <article className='about-card'>
                        <h3>
                            Banking Law
                        </h3>
                        <p className='section-paragraph'>
                            The Anjouan Offshore Finance Authority has certain requirements for a Banking License to be
                            satisfied by all offshore banks, namely the Banking Law. The key features of the Law are as
                            follows: The Law establishes a licensing regime for banks and savings institutions; licences
                            which are issued by the Anjouan Offshore Finance Authority are for offshore banks only. A
                            Bank must inform the Anjouan Offshore Finance Authority about any change in its share
                            ownership.
                        </p>
                    </article>
                    <article className='about-card'>
                        <h3>
                            Licenses
                        </h3>
                        <p className='section-paragraph'>
                            <h2>
                                <TiTick/>
                            </h2>
                            International Banking Licenses
                        </p>
                        <p className='section-paragraph'>
                            <h2>
                                <TiTick/>
                            </h2>
                            Insurance Licenses
                        </p>
                        <p className='section-paragraph'>
                            <h2>
                                <TiTick/>
                            </h2>
                            Online Gaming Licenses
                        </p>
                        <p className='section-paragraph'>
                            <h2>
                                <TiTick/>
                            </h2>
                            Brokerage License
                        </p>
                    </article>
                    <article className='about-card'>
                        <h3>
                            Services
                        </h3>
                        <p className='section-paragraph'>
                            <h2>
                                <TiTick/>
                            </h2>
                            International Banking Companies (IBC)
                        </p>
                        <p className='section-paragraph'>
                            <h2>
                                <TiTick/>
                            </h2>
                            Fully serviced offices in
                            Mutsamudu
                        </p>
                        <p className='section-paragraph'>
                            <h2>
                                <TiTick/>
                            </h2>
                            Citizenship Program
                        </p>

                    </article>
                </div>
            </div>
        </section>
    </main>
);

export default Banking;
