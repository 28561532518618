import React, { useState } from 'react';

//images 
import AnjouanMap_1 from '../../../assets/img/anjouan-map-01.png';
import AnjouanMap_2 from '../../../assets/img/anjouan-map-02.png';

// icons
import { TiTick } from "react-icons/ti";


const OnlineGaming = () => (
    <main className='main'>
        <section className="about-section">
            <div className="section-heading-container">
                <h2 className='section-heading'>
                    Online Gaming
                </h2>
            </div>

            <div className="banking-container container grid">
                <div className="about-content">
                    <p className='section-paragraph'>
                        Online Betting and gaming licenses are issued only to Anjouan companies, but allow a company to engage in as many different kinds of on line gambling promotions and under as many different brand names or web addresses as desired. Local nominee directors are acceptable if required. A fixed fee per year is charged. The Server may be located anywhere in the world, and profits may be cleared through any bank.
                    </p>
                    <h3>
                        Betting and Gaming Law
                    </h3>
                    <p className='section-paragraph'>
                        The Betting and Gaming Act 2005 was passed to legalise and regulate computer internet gaming, horse or greyhound betting and sports book betting, Casino, lottery and bingo on line gaming, to establish a Gaming Board, and to provide for related or incidental matters.
                    </p>

                    <h3>
                        Formation
                    </h3>
                    <p className='container-paragraph'>
                        The formation of an Online betting or gaming company has to be made on the application form as stipulated in the act and the necessary KYC and due diligence documents as below to the Anjouan Corporate Services Ltd and Gaming Board of Anjouan. The Gaming Board of Anjouan will then assess the application the Directors and shareholders before grating the permission for the license.
                    </p>

                    <h3 className='container-title'>
                        KYC AND DUE DILIGENCE
                    </h3>
                    <p className='container-paragraph'>
                        Please submit the following documents for each of the beneficial owner, director, manager, authorised signatory, shareholder/member of the Entity or agent under power of attorney.
                    </p>
                    
                    <h3 className='container-title'>
                        (a) For Individual
                    </h3>
                    <p className='container-paragraph'>
                        A certified copy of passport or current driving licence or national identity card showing the name, date and place of birth, nationality, signature of holder and data of issue/expiry. The document must be certified by a banker, lawyer or actuary or notary or accountant holding a recognized professional qualification. Proof of residential address. Acceptable evidence includes an original utility bill, bank or credit card statement. 
                        <br/>
                        Original Bank Reference
                        <br/>
                         Certificate of Morality (Affidavit confirming that the individuals have no criminal track record).                    </p>
                    
                    <h3 className='container-title'>
                        (b) For Corporate/Partnership
                    </h3>
                    <p className='container-paragraph'>
                        Certificate of incorporation or registration and the memorandum and articles of association or constitution or deed of partnership or Act de Société as the case may be. Document has to be certified by banker, lawyer or actuary or notary or accountant holding a recognized professional qualification. Original Bank Reference.
                    </p>
                </div>

                <div className='about-card-container'>
                    <article className='about-card'>
                        <h3>
                            Licenses
                        </h3>
                        <p className='section-paragraph'>
                            <h2>
                                <TiTick/>
                            </h2>
                            International Banking Licenses
                        </p>
                        <p className='section-paragraph'>
                            <h2>
                                <TiTick/>
                            </h2>
                            Insurance Licenses
                        </p>
                        <p className='section-paragraph'>
                            <h2>
                                <TiTick/>
                            </h2>
                            Online Gaming Licenses
                        </p>
                        <p className='section-paragraph'>
                            <h2>
                                <TiTick/>
                            </h2>
                            Brokerage License
                        </p>
                    </article>
                    <article className='about-card'>
                        <h3>
                            Services
                        </h3>
                        <p className='section-paragraph'>
                            <h2>
                                <TiTick/>
                            </h2>
                            International Banking Companies (IBC)
                        </p>
                        <p className='section-paragraph'>
                            <h2>
                                <TiTick/>
                            </h2>
                            Fully serviced offices in
                            Mutsamudu
                        </p>
                        <p className='section-paragraph'>
                            <h2>
                                <TiTick/>
                            </h2>
                            Citizenship Program
                        </p>
                    </article>
                </div>
            </div>
        </section>
    </main>
);

export default OnlineGaming;
