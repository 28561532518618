import React, { useState } from 'react';
import './Contact.css'

// icons
import { TiTick } from "react-icons/ti";


const Contact = () => (
    <main className='main'>
        <section className="about-section">
            <div className="contact-heading-container">
                <h3 className='section-subtitle'>
                    <strong>
                        CONTACT US
                    </strong>
                </h3>
                <h1 className='section-heading'>
                    GET IN TOUCH
                </h1>

                <h3>
                    info@anjouanoffshorefinanceauthority.com
                </h3>
            </div>

        </section>
    </main>
);

export default Contact;
