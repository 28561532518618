import React, { useState } from 'react';

// icons
import { TiTick } from "react-icons/ti";


const BankingCrypto = () => (
    <main className='main'>
        <section className="about-section">
            <div className="section-heading-container">
                <h2 className='section-heading'>
                    BANKING WITH CRYPTO
                </h2>
            </div>

            <div className="banking-container container grid">
                <div className="">
                    <p className='container-paragraph'>
                        The OFA has specific requirements for a Banking with Crypto License under the Banking Law. Here are the key details:
                    </p>
                 
                    <p className='container-paragraph'>
                        <strong>License Type:</strong> The only license offered in Anjouan for this purpose is an International Banking License (Class B), designated for offshore banks only. This is a full-unrestricted license allowing for all banking services outside of Anjouan, Union of Comoros.
                    </p>
                    <p className='container-paragraph'>
                        <strong>Services Included:</strong> The license covers traditional banking services and extends to investment banking. It also includes operations in major foreign currencies and allows for services such as fund administration, custodial services, trusteeship, international portfolio management, money market, and other treasury operations, specifically tailored to include crypto-related financial services.
                    </p>
                    <p className='container-paragraph'>
                        <strong>Regulatory Framework:</strong> The Law establishes a licensing regime managed by the OFA, where banks are required to notify the OFA of any changes in share ownership.
                    </p>

                    <h3 className='container-title'>
                        Formation and Application Process:
                    </h3>
                    <p className='container-paragraph'>
                        <strong>Efficiency:</strong> Forming a bank with a crypto license is streamlined due to minimal bureaucracy, with approvals granted swiftly if all requirements are satisfied.
                    </p>
                    <p className='container-paragraph'>
                        <strong>No Local Office Requirement:</strong> While not mandatory, it is suggested that banks maintain local offices and staff, which can be arranged at minimal cost.
                    </p>
                    <p className='container-paragraph'>
                        <strong>Application Steps:</strong> Applicants need to first secure an International Business Company (IBC) registration before applying for the banking license with the OFA.
                    </p>

                    <h3 className='container-title'>
                        Documentation and Due Diligence:
                    </h3>
                    <p className='container-paragraph'>
                        <strong>Business Plan:</strong> Must detail the bank's activities, target investment countries, shareholder percentages, and information on ultimate beneficial owners.
                    </p>
                    <p className='container-paragraph'>
                        <strong>KYC Requirements:</strong> Includes detailed CVs of directors, contact details for directors and shareholders, bank references for beneficial owners, and certified passport copies and proof of address for all beneficial owners.
                    </p>
                    <p className='container-paragraph'>
                        <strong>Legal Documentation:</strong> Necessary for shareholding companies and includes articles of incorporation, latest audited financial statements, and a certificate of criminal record absence for beneficial owners.
                    </p>
                    
                    <h3 className='container-title'>
                        Compliance:
                    </h3>
                    <p className='container-paragraph'>
                        <strong>Annual Accounts Submission:</strong> Banks are required to submit annual accounts to the Anjouan Offshore Finance Authority.
                    </p>
                    
                    <h3 className='container-title'>
                        KYC and Additional Due Diligence for Crypto Operations:
                    </h3>
                    <p className='container-paragraph'>
                        <strong>Enhanced Due Diligence:</strong> Given the involvement with cryptocurrencies, banks may be subjected to enhanced due diligence processes, reflecting the added complexities and regulatory scrutiny associated with crypto transactions.
                        This specialized banking license facilitates the integration of traditional banking activities with the emerging demands of the cryptocurrency market, under a regulated framework that promotes transparency and compliance with international financial standards.                    
                    </p>
                    
                </div>

                <div className='about-card-container'>
                    <article className='about-card'>
                        <h3>
                            Licenses
                        </h3>
                        <p className='section-paragraph'>
                            <h2>
                                <TiTick/>
                            </h2>
                            International Banking Licenses
                        </p>
                        <p className='section-paragraph'>
                            <h2>
                                <TiTick/>
                            </h2>
                            Insurance Licenses
                        </p>
                        <p className='section-paragraph'>
                            <h2>
                                <TiTick/>
                            </h2>
                            Online Gaming Licenses
                        </p>
                        <p className='section-paragraph'>
                            <h2>
                                <TiTick/>
                            </h2>
                            Brokerage License
                        </p>
                    </article>
                    <article className='about-card'>
                        <h3>
                            Services
                        </h3>
                        <p className='section-paragraph'>
                            <h2>
                                <TiTick/>
                            </h2>
                            International Banking Companies (IBC)
                        </p>
                        <p className='section-paragraph'>
                            <h2>
                                <TiTick/>
                            </h2>
                            Fully serviced offices in
                            Mutsamudu
                        </p>
                        <p className='section-paragraph'>
                            <h2>
                                <TiTick/>
                            </h2>
                            Citizenship Program
                        </p>

                    </article>
                </div>
            </div>
        </section>
    </main>
);

export default BankingCrypto;
