import React, { useState } from 'react';
import './ActsDocuments.css'

// icons
import { TiTick } from "react-icons/ti";


const ActsDocuments = () => (
    <main className='main'>
        <section className="about-section">
            <div className="section-heading-container">
                <h2 className='section-heading'>
                    ACTS AND DOCUMENTS
                </h2>
            </div>

            <div className="banking-container container grid">

                <div className='acts-documents-content'>
                    <p className='section-paragraph'>
                        <h2>
                            <TiTick/>
                        </h2>
                        Anjouan Endorsed Letter From Minister Of State
                    </p>
                    <p className='section-paragraph'>
                        <h2>
                            <TiTick/>
                        </h2>
                        International Business Companies Act 2005
                    </p>
                    <p className='section-paragraph'>
                        <h2>
                            <TiTick/>
                        </h2>
                        International Business Company Regulations 2005
                    </p>
                    <p className='section-paragraph'>
                        <h2>
                            <TiTick/>
                        </h2>
                        International Insurance Act 2005
                    </p>
                    <p className='section-paragraph'>
                        <h2>
                            <TiTick/>
                        </h2>
                        International Bank regulations 2005
                    </p>
                    <p className='section-paragraph'>
                        <h2>
                            <TiTick/>
                        </h2>
                        International Banks Act 2005
                    </p>
                    <p className='section-paragraph'>
                        <h2>
                            <TiTick/>
                        </h2>
                        Offshore Finance Authority Act 2005
                    </p> 
                    <p className='section-paragraph'>
                        <h2>
                            <TiTick/>
                        </h2>
                        Money Laundering (Prevention) Act 2005
                    </p>
                      <p className='section-paragraph'>
                        <h2>
                            <TiTick/>
                        </h2>
                          Comoros Islands legal dispute
                      </p>
                    
                </div>

                <div className='about-card-container'>
                    <article className='about-card'>
                        <h3>
                            Licenses
                        </h3>
                        <p className='section-paragraph'>
                            <h2>
                                <TiTick/>
                            </h2>
                            International Banking Licenses
                        </p>
                        <p className='section-paragraph'>
                            <h2>
                                <TiTick/>
                            </h2>
                            Insurance Licenses
                        </p>
                        <p className='section-paragraph'>
                            <h2>
                                <TiTick/>
                            </h2>
                            Online Gaming Licenses
                        </p>
                        <p className='section-paragraph'>
                            <h2>
                                <TiTick/>
                            </h2>
                            Brokerage License
                        </p>
                    </article>
                    <article className='about-card'>
                        <h3>
                            Services
                        </h3>
                        <p className='section-paragraph'>
                            <h2>
                                <TiTick/>
                            </h2>
                            International Banking Companies (IBC)
                        </p>
                        <p className='section-paragraph'>
                            <h2>
                                <TiTick/>
                            </h2>
                            Fully serviced offices in
                            Mutsamudu
                        </p>
                        <p className='section-paragraph'>
                            <h2>
                                <TiTick/>
                            </h2>
                            Citizenship Program
                        </p>
                    </article>
                </div>
            </div>
        </section>
    </main>
);

export default ActsDocuments;
