import React from "react";
import Logo from '../../assets/img/logo.png'
const Footer = () => (
    <footer className="footer">
        <div className="footer-container container">
            <a href="#" className="footer-logo">
                <img src={Logo} className="footer-logo-img" alt="Footer Logo" />
            </a>
            <p className="footer-title">info@anjouanoffshorefinanceauthority.com</p>
            <p className="footer-title">
                For all questions please contact the licensed representative - <span className="red-span">anjouanregister.com</span> (ANJOUAN CORPORATE REGISTER)
            </p>
            <span className="footer-copy-right">
        © 2024 Anjouan Offshore Finance Authority. All Rights Reserved.
      </span>
        </div>
    </footer>
);

export default Footer;