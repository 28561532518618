import React, { useState } from 'react';
import './Home.css'; // Assuming your CSS is saved here

const Home = () => (
    <main className='main'>
        <section className="home-section section">
            <div className="home-container container">
                <div className="home-row">
                    <h1 className="section-title">
                        Anjouan Offshore Finance <span>Authority</span>
                    </h1>
                    <p className="home-description">
                        Welcome to the official website of the Anjouan Offshore Finance Authority. This website serves
                        as a platform to provide valuable information and resources related to the licensing and
                        regulatory services Issuing Licenses for Banking, Gaming, Brokerage/FX, Crypto, and Insurance on
                        behalf of the Anjouan Government, AOFA are the official Government authority.
                    </p>
                </div>
            </div>

            <div className="home-figure-container">
                <div className="home-figure"></div>
            </div>

            <div className="home-container container">
                <div className="home-details">
                    <h3 className="red-span">KEY FEATURES</h3>
                    <h1 className="section-title">Welcome To The Anjouan Offshore Finance Authority.</h1>
                    <p className="home-details-description">
                        Anjouan, one of the three Autonomous Islands of the Union of the Comoros, is a sovereign island
                        nation situated in the Indian Ocean. Renowned for its favourable regulatory environment and
                        attractive tax regime, Anjouan has emerged as a popular jurisdiction for businesses looking to
                        establish an offshore presence. Whether you’re an entrepreneur, investor, or a company seeking
                        strategic advantages, Anjouan offers a range of opportunities to thrive in a dynamic global
                        marketplace.
                    </p>
                    <p className="home-details-description">
                        Anjouan is strategically located at the northern end of the Mozambique Channel in the Indian
                        Ocean. As part of the Union of the Comoros, an independent country comprised of three islands in
                        south eastern Africa, Anjouan benefits from its association with a stable political environment
                        and a growing economy. The capital and largest city of the Comoros is Moroni, serving as a
                        gateway to this vibrant region.
                    </p>
                </div>
            </div>
        </section>
    </main>
);

export default Home;
