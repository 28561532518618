import React, { useState } from 'react';
import './AnjouanRegister.css'

const AnjouanRegister = () => (
    <main className='main'>
        <section className="about-section">
            <div className="section-heading-container">
                <h2 className='section-heading'>
                    ANJOUAN REGISTER
                </h2>
            </div>

            <div className='anjouan-register-container container grid'>
                <h3 className='anjouan-register-title'>LIST OF COMPANIES REGISTERED WITH AOFA</h3>

                <div className="table-responsive">
                    <table className="responsive-table">
                        <caption className='anjouan-table-caption'>Banking License</caption>
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>License number</th>
                            <th>Date of issue</th>
                        </tr>
                        </thead>
                        <tbody>
                        {BankingLicense.map((item, index) => (
                            <tr key={index}>
                                <td>{item.name}</td>
                                <td>{item.license_number}</td>
                                <td>{item.Date_of_issue}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <table className="responsive-table">
                        <caption className='anjouan-table-caption'>Brokerage License</caption>
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>License number</th>
                            <th>Date of issue</th>
                        </tr>
                        </thead>
                        <tbody>
                        {BrokerageLicense.map((item, index) => (
                            <tr key={index}>
                                <td>{item.name}</td>
                                <td>{item.license_number}</td>
                                <td>{item.Date_of_issue}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <table className="responsive-table">
                        <caption className='anjouan-table-caption'>Insurance License</caption>
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>License number</th>
                            <th>Date of issue</th>
                        </tr>
                        </thead>
                        <tbody>
                        {InsuranceLicense.map((item, index) => (
                            <tr key={index}>
                                <td>{item.name}</td>
                                <td>{item.license_number}</td>
                                <td>{item.Date_of_issue}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <table className="responsive-table">
                        <caption className='anjouan-table-caption'>Gaming License</caption>
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>License number</th>
                            <th>Date of issue</th>
                        </tr>
                        </thead>
                        <tbody>
                        {GamingLicense.map((item, index) => (
                            <tr key={index}>
                                <td>{item.name}</td>
                                <td>{item.license_number}</td>
                                <td>{item.Date_of_issue}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>


        </section>
    </main>
);

export default AnjouanRegister;


// Banking License
const BankingLicense = [
    {
        name: "West Point Bank Ltd",
        license_number: "L14892 / WPB",
        Date_of_issue: "27.06.2016",
    },
    {
        name: "Overstate Bank Ltd",
        license_number: "L14675 / OBL",
        Date_of_issue: "05.07.2016",
    },
    {
        name: "Crystal Rise Bank Ltd",
        license_number: "L14978 / CRB",
        Date_of_issue: "19.01.2016",
    },
    {
        name: "Atlantis Group Bank Ltd",
        license_number: "L14295 / AGB",
        Date_of_issue: "25.05.2016",
    },
    {
        name: "Gold Star Bank Ltd",
        license_number: "L14289 / GSB",
        Date_of_issue: "05.08.2016",
    },
    {
        name: "FEA Group Bank Ltd",
        license_number: "L14679 / FEA",
        Date_of_issue: "09.08.2016",
    },
    {
        name: "Trade Service Bank Ltd",
        license_number: "L14685 / TSB",
        Date_of_issue: "11.09.2016",
    },
    {
        name: "Lionhart Bank Ltd",
        license_number: "L14439 / LBL",
        Date_of_issue: "25.10.2016",
    },
    {
        name: "Second Century Bank Ltd",
        license_number: "L14119 / SCB",
        Date_of_issue: "05.12.2016",
    },
    {
        name: "Oriental Finance Bank Ltd",
        license_number: "L 15641 / OFB",
        Date_of_issue: "10.09.2017",
    },
    {
        name: "Western Finance Bank Ltd",
        license_number: "L 15155 / wfb",
        Date_of_issue: "10.09.2017",
    },
    {
        name: "European Group Bank Ltd",
        license_number: "L 15000 / UGB",
        Date_of_issue: "09.09.2017",
    },
    {
        name: "Inter-world Bank Ltd",
        license_number: "L 15098 / IBL",
        Date_of_issue: "10.09.2017",
    },
    {
        name: "Universal Group Bank Ltd",
        license_number: "L 15659 / UGB",
        Date_of_issue: "10.09.2017",
    },
    {
        name: "Crypto Group Bank Ltd",
        license_number: "L 16398 / CGB",
        Date_of_issue: "18.08.2018",
    },
    {
        name: "Blockchain Finance Bank Ltd",
        license_number: "L 16889 / BFB",
        Date_of_issue: "21.11.2018",
    },
    {
        name: "Universal Group Bank",
        license_number: "L 16214 / REB",
        Date_of_issue: "10.07.2019",
    },
    {
        name: "Suisse Bank PLC",
        license_number: "L 16214 / SB",
        Date_of_issue: "10.10.2019",
    },
    {
        name: "Capital Commercial Bank Ltd",
        license_number: "L 16509 / CCB",
        Date_of_issue: "25.10.2019",
    },
    {
        name: "Rochester Bank S.A.",
        license_number: "L 16994 / RBSA",
        Date_of_issue: "26.02.2020",
    },
    {
        name: "Wealth Capital Bank Ltd",
        license_number: "L 20074 / WCB",
        Date_of_issue: "28.02.2020",
    },
    {
        name: "Cyber Group Bank",
        license_number: "L 20741 / CGB",
        Date_of_issue: "25.04.2020",
    },
    {
        name: "Credit Group International Bank",
        license_number: "L 20771 / CGI",
        Date_of_issue: "22.05.2020",
    },
    {
        name: "Retro International Bank",
        license_number: "L 20294 / RIB",
        Date_of_issue: "18.06.2020",
    },
    {
        name: "Secure Bank Ltd",
        license_number: "L 20996 / SBL",
        Date_of_issue: "06.07.2020",
    },
    {
        name: "Pan Pacific Brokerage Ltd",
        license_number: "BL 66149 / PPB",
        Date_of_issue: "07.09.2018",
    },
    {
        name: "Celestial Bank Ltd.",
        license_number: "L0381/CBL",
        Date_of_issue: "12.01.2022",
    },
    {
        name: "Eclipse Banking Corp.",
        license_number: "L0432/EBC",
        Date_of_issue: "18.06.2023",
    },
    {
        name: "Lunar Financial Services",
        license_number: "L0355/LFS",
        Date_of_issue: "23.11.2021",
    },
     {
        name: "Solaris Bank LLC",
        license_number: "L0462/SBL",
        Date_of_issue: "05.09.2024",
    },
     {
        name: "Orbit Trust Bank",
        license_number: "L0338/OTB",
        Date_of_issue: "08.04.2021",
    },
];

// Brokerage License
const BrokerageLicense = [
    {
        name: "Atlantic Overseas Bank Ltd",
        license_number: "L14107 / AOB",
        Date_of_issue: "28.07.2016",
    },
    {
        name: "Unigroup Brokerage Company Ltd",
        license_number: "BL 26194 / UNI",
        Date_of_issue: "04.04.2016",
    },
   {
        name: "Pacific Brokerage Group Ltd",
        license_number: "BL 72196 / PBG",
        Date_of_issue: "16.01.2017",
    },
   {
        name: "Secure Brokerage Company Ltd",
        license_number: "BL 62147 / SBL",
        Date_of_issue: "18.04.2017",
    },
   {
        name: "Citynet Group Bank",
        license_number: "BL 15267 / CGB",
        Date_of_issue: "10.09.2017",
    },
   {
        name: "Capital Brokerage Company Ltd",
        license_number: "BL 52612 / CBC",
        Date_of_issue: "06.06.2018",
    },
   {
        name: "Fintec Brokerage Ltd",
        license_number: "BL 19907 / FBL",
        Date_of_issue: "18.03.2019",
    },
   {
        name: "HS Brokerage Ltd",
        license_number: "BL 19876 / HSB",
        Date_of_issue: "29.10.2019",
    },
   {
        name: "Sage Brokerage Ltd",
        license_number: "BL 20799 / SBL",
        Date_of_issue: "02.03.2020",
    },
   {
        name: "Asia Brokerage Ltd",
        license_number: "BL 20008 / ABL",
        Date_of_issue: "06.11.2020",
    },
   {
        name: "Black Star Insurance Ltd",
        license_number: "L 19334 / BSI",
        Date_of_issue: "11.06.2019",
    },
   {
        name: "Quantum Brokers Ltd.",
        license_number: "BL0392/QBL",
        Date_of_issue: "15.03.2022",
    },
   {
        name: "Orion Trading Corp.",
        license_number: "BL0427/OTC",
        Date_of_issue: "22.07.2023",
    },
   {
        name: "Galactic Securities Inc.",
        license_number: "BL0345/GSI",
        Date_of_issue: "06.05.2021",
    },
   {
        name: "Nebula Investments LLC",
        license_number: "BL0455/NIL",
        Date_of_issue: "09.02.2024",
    },
   {
        name: "Astro Brokerage Services",
        license_number: "BL0360/ABS",
        Date_of_issue: "29.10.2021",
    },
];

// Insurance License
const InsuranceLicense = [
    {
        name: "Pacific West Insurance Ltd",
        license_number: "L 76219 / PWI",
        Date_of_issue: "16.05.2016",
    },
    {
        name: "Unigroup Insurance Ltd",
        license_number: "L 79214 / UIL",
        Date_of_issue: "18.08.2017",
    },
   {
        name: "Allied Insurance Group Ltd",
        license_number: "L 69614 / AIG",
        Date_of_issue: "18.10.2018",
    },
   {
        name: "White Plains Insurance Ltd",
        license_number: "L 69214 / WPI",
        Date_of_issue: "16.06.2018",
    },
   {
        name: "Pacific Rim Insurance Ltd",
        license_number: "L 19991 / PRI",
        Date_of_issue: "11.11.2019",
    },
   {
        name: "Solid Reinsurance Ltd",
        license_number: "L 20219 / SRL",
        Date_of_issue: "07.07.2020",
    },
   {
        name: "Royal Chartered Insurance",
        license_number: "L 20491 / RCI",
        Date_of_issue: "28.12.2020",
    },
   {
        name: "Astro Insure Ltd.",
        license_number: "L0399/AIL",
        Date_of_issue: "19.05.2022",
    },
   {
        name: "Orion Risk Solutions LLC",
        license_number: "L0458/ORS",
        Date_of_issue: "27.10.2023",
    },
   {
        name: "Lunar Assurance Corp.",
        license_number: "L0331/LAC",
        Date_of_issue: "14.03.2021",
    },
   {
        name: "Solar Protection Services",
        license_number: "L0481/SPS",
        Date_of_issue: "30.06.2024",
    },
   {
        name: "Nebula Coverage Inc.",
        license_number: "L0366/NCI",
        Date_of_issue: "08.12.2021",
    },
];

// Gaming License
const GamingLicense = [
    {
        name: "Play My Game Ltd",
        license_number: "L 87796",
        Date_of_issue: "14.08.2018",
    },
     {
        name: "Ace High Casino",
        license_number: "L 77750",
        Date_of_issue: "18.12.2018",
    },
     {
        name: "HMS Gaming Ltd",
        license_number: "L 19179 / HMS",
        Date_of_issue: "16.09.2019",
    },
     {
        name: "High Tec Gaming Ltd",
        license_number: "L 20291 / HTG",
        Date_of_issue: "06.04.2020",
    },
     {
        name: "Stellar Play Studios Inc.",
        license_number: "L0410/SPS",
        Date_of_issue: "03.02.2022",
    },
     {
        name: "Quantum Gaming Ltd.",
        license_number: "L0448QGL",
        Date_of_issue: "15.08.2023",
    },
     {
        name: "Galaxy Games LLC",
        license_number: "L0375/GGL",
        Date_of_issue: "",
    },
     {
        name: "Nebula Interactive Corp.",
        license_number: "L0473/NIC",
        Date_of_issue: "21.07.2024",
    },
     {
        name: "Cosmo GameWorks",
        license_number: "L0321/CGW",
        Date_of_issue: "25.01.2021",
    }
];