import React, { useState } from 'react';

// icons
import { TiTick } from "react-icons/ti";


const Brokerage = () => (
    <main className='main'>
        <section className="about-section">
            <div className="section-heading-container">
                <h2 className='section-heading'>
                    BROKERAGE
                </h2>
            </div>

            <div className="banking-container container grid">
                <div className="about-content">
                    <p className='section-paragraph'>
                        Brokerage and Clearing House License are only issued to Anjouan International Business
                        Companies. The company can engage in the following activities: </p>
                    <h3>
                        Formation
                    </h3>
                    <p className='section-paragraph'>
                        Forming a brokerage is a relatively quick process due to the lack of bureaucracy. Approval can
                        be granted swiftly if the necessary requirements are met. There are no requirements for local
                        offices or staff, although we would suggest that all companies have this facility which can be
                        arranged at a minimal cost ( see Local office facility ). Application for permission for a
                        Brokerage licence should be made to Application Form License </p>

                    <h4>
                        The following documents will be required:
                    </h4>
                    <p className='container-paragraph'>
                        The percentage of ownership by the shareholders
                        <br/>
                        The details of the ultimate beneficial owners (chart may help if many)
                        <br/>
                        Any other useful information to support the application.
                        <br/>
                        CVs of all the directors
                        <br/>
                        Full contact details of the directors and shareholders (KYC questionnaire)
                        <br/>
                        Bank reference for all the beneficial owners
                        <br/>
                        Clear certified passport copies of all beneficial owners
                        <br/>
                        Proof of addresses (utility bills)
                        <br/>
                        Business or legal reference for the beneficial owners
                        <br/>
                        Legal doc for the shareholding companies (articles, certificate of incorporation etc)
                        <br/>
                        Latest audited financial statements for all shareholding companies (if any)
                        <br/>
                        A certificate to prove that the beneficial owners do not have a criminal record
                    </p>

                    <h3 className='container-title'>
                        KYC AND DUE DILIGENCE
                    </h3>
                    <p className='container-paragraph'>
                        A charge is made for each director / shareholder for due diligence (cost is dependent of country
                        of residence ), in the event that a client is not accepted for a license this payment is not
                        refundable
                    </p>
                    <p className='container-paragraph'>
                        Please submit the following documents for each of the beneficial owner, director, manager, authorised signatory, shareholder/member of the Entity or agent under power of attorney.
                    </p>
                    
                    <h3 className='container-title'>
                        (a) For Individual
                    </h3>
                    <p className='container-paragraph'>
                        A certified copy of passport or current driving licence or national identity card showing the name, date and place of birth, nationality, signature of holder and data of issue/expiry. The document must be certified by a banker, lawyer or actuary or notary or accountant holding a recognized professional qualification. Proof of residential address. Acceptable evidence includes an original utility bill, bank or credit card statement. Original Bank Reference.
                    </p>
                    
                    <h3 className='container-title'>
                        (b) For Corporate/Partnership
                    </h3>
                    <p className='container-paragraph'>
                        Certificate of incorporation or registration and the memorandum and articles of association or constitution or deed of partnership or Act de Société as the case may be. Document has to be certified by banker, lawyer or actuary or notary or accountant holding a recognized professional qualification. Original Bank Reference. A certified true copy of latest audited account or annual report. A Certificate of good standing (for Société). List of substantial shareholders (who holds not less than 5% of the voting power exercisable at the meeting of shareholder/member) and a list of directors, and corporate structure. A certified copy of Board resolution granting authority to its managers, officers or employees to transact business on its behalf.
                    </p>
                </div>

                <div className='about-card-container'>
                    <article className='about-card'>
                        <h3>
                            Licenses
                        </h3>
                        <p className='section-paragraph'>
                            <h2>
                                <TiTick/>
                            </h2>
                            International Banking Licenses
                        </p>
                        <p className='section-paragraph'>
                            <h2>
                                <TiTick/>
                            </h2>
                            Insurance Licenses
                        </p>
                        <p className='section-paragraph'>
                            <h2>
                                <TiTick/>
                            </h2>
                            Online Gaming Licenses
                        </p>
                        <p className='section-paragraph'>
                            <h2>
                                <TiTick/>
                            </h2>
                            Brokerage License
                        </p>
                    </article>
                    <article className='about-card'>
                        <h3>
                            Services
                        </h3>
                        <p className='section-paragraph'>
                            <h2>
                                <TiTick/>
                            </h2>
                            International Banking Companies (IBC)
                        </p>
                        <p className='section-paragraph'>
                            <h2>
                                <TiTick/>
                            </h2>
                            Fully serviced offices in
                            Mutsamudu
                        </p>
                        <p className='section-paragraph'>
                            <h2>
                                <TiTick/>
                            </h2>
                            Citizenship Program
                        </p>
                    </article>
                </div>
            </div>
        </section>
    </main>
);

export default Brokerage;
